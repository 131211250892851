<!-- 新建收货地址 -->
<template>
	<el-dialog id="newAddress_container" :before-close="handleClose" :visible.sync="dialogVisible" title="新增地址">
		<el-form ref="form" :inline="true" :model="form" :rules="rules" label-position="top" size="small">
			<el-form-item class="newAddress__formItem-consignee" label="姓名" prop="consignee">
				<el-input v-model="form.consignee" autocomplete="off" placeholder="请输入收件人姓名" />
			</el-form-item>
			
			<el-form-item class="newAddress__formItem-phone" label="手机号" prop="phone">
				<el-input v-model="form.phone" autocomplete="off" placeholder="请输入手机号码"></el-input>
			</el-form-item>
			
			<!--      <el-form-item label="公司名称">-->
			<!--        <el-input-->
			<!--            v-model="form.corp"-->
			<!--            autocomplete="off"-->
			<!--            placeholder="请输入公司名称"-->
			<!--        ></el-input>-->
			<!--      </el-form-item>-->
			
			<div>
				<el-form-item label="地区" prop="value" style="width: 100%">
					<el-cascader
							ref="cascader"
							v-model="form.value"
							:options="areaTreeList"
							:props="{ label: 'extName', value: 'id' }"
							placeholder="选择省/市/区" style="width: 100%;" />
				</el-form-item>
			</div>
			
			<div>
				<el-form-item label="详细地址" prop="detailAddress" style="width: 100%">
					<el-input v-model="form.detailAddress" :rows="5" placeholder="请输入详细地址" type="textarea"></el-input>
				</el-form-item>
			</div>
		</el-form>
		
		<div slot="footer" class="dialog-footer">
			<el-checkbox
					v-model="form.defaultStatus"
					class="fl"
					false-label="0"
					true-label="1"
			>设为默认
			</el-checkbox>
			<el-button size="small" type="primary" @click="saveShippingAddress">保存地址</el-button>
		</div>
	</el-dialog>
</template>
<script>
import { areaTreeList, saveReceiveAddress } from '@/api/membership.js';
import { rules_mobile } from "@/utils/validate";

export default {
	props: ['dialogVisible'],
	data () {
		let areaValidate = (rule, value, callback) => {
			if(value.length === 0) {
				callback(new Error('请选择地区'));
			} else {
				callback();
			}
		};
		let validateConsignee = (rule, value, callback) => {
			let reg = new RegExp("^([\u4E00-\uFA29]|[\uE7C7-\uE7F3]|[a-zA-Z0-9_-]){1,20}$");//包含“-”
			if(!reg.test(value)) {
				callback([new Error('姓名输入不合法')]);
			} else {
				callback();
			}
		};
		
		return {
			// 省市区地址
			form: {
				consignee: '',
				phone: '',
				province: '',
				city: '',
				region: '',
				detailAddress: '',
				defaultStatus: 0,
				memberId: '',
				corp: '',
				value: [],
			},
			rules: {
				consignee: [
					{required: true, message: "请输入姓名", trigger: "blur"},
					{validator: validateConsignee, trigger: 'blur'}
				],
				phone: [...rules_mobile()],
				detailAddress: [{required: true, message: "请输入详细地址", trigger: "blur"}],
				value: [{required: true, message: "请选择地区", trigger: "change"}, {validator: areaValidate, trigger: "change"}]
			}
		};
	},
	methods: {
		handleClose (done) {
			this.$confirm('确认关闭？').then(_ => {
				this.form.defaultStatus = 0;
				this.$refs.form.resetFields();
				this.$emit('newAddressSuccess');
			}).catch(_ => {
			});
		},
		removeEmptyArr (arr) {
			arr.forEach(item => {
				if(item.children.length == 0) {
					delete item.children;
				} else {
					this.removeEmptyArr(item.children);
				}
			});
		},
		saveShippingAddress () {
			this.$refs["form"].validate((valid) => {
				if(valid) {
					var checkedNodes = this.$refs.cascader.getCheckedNodes();
					var pathLabels = checkedNodes[0].pathLabels;
					
					var receiveAddressVo = Object.assign(this.form, {
						memberId: this.$store.state.user.uid,
						country: pathLabels[0],
						countryCode: this.form.value[0],
						
						province: pathLabels[1],
						provinceCode: this.form.value[1],
						
						city: pathLabels[2],
						cityCode: this.form.value[2],
						
						district: pathLabels[3],
						districtCode: this.form.value[3],
					});
					saveReceiveAddress(receiveAddressVo).then(data => {
						this.$alert('新增地址成功', '提示', {
							confirmButtonText: '确定',
							callback: action => {
								this.$emit('newAddressSuccess');
								this.isOpenDialog = false;
								this.form.defaultStatus = 0;
								this.$refs.form.resetFields();
							}
						});
					});
				}
			})
		}
	},
	created () {
		this.areaTreeList = [];
		areaTreeList().then(data => {
			this.removeEmptyArr(data.data);
			this.areaTreeList = data.data;
		});
	}
};
</script>
<style lang="scss">
@import '@/styles/common.scss';

#newAddress_container {
	.el-dialog {
		width: rem(696px);
		// height: rem(569px);
		padding: rem(33px) rem(40px);
		
		.el-dialog__title {
			font-size: 16px;
			font-weight: bold;
		}
		
		.el-dialog__header {
			padding: 0;
			padding-bottom: rem(24px);
		}
		
		.el-dialog__body {
			padding: 0;
			
			.el-form-item__label {
				line-height: normal;
			}
		}
		
		.el-dialog__footer {
			padding: 0;
			line-height: 40px;
			
			.dialog-footer {
				text-align: right;
			}
		}
	}
	
	.newAddress__formItem-consignee {
		margin-right: 40px;
		width: 200px;
	}
	
	.newAddress__formItem-phone {
		width: 200px;
	}
}
</style>
